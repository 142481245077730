import '../../../assets/icons/icon-arrow-back.svg';
import '../../../assets/icons/icon-arrow-forward.svg';
import '../../../assets/icons/icon-arrow-down.svg';
import '../../../assets/icons/icon-questions-arrow.svg';

//------------------ used
import '../../../assets/icons/icon-logo.svg';
import '../../../assets/icons/icon-point.svg';
import '../../../assets/icons/icon-user-lk.svg';
import '../../../assets/icons/icon-phone.svg';
import '../../../assets/icons/icon-search.svg';
import '../../../assets/icons/icon-email.svg';
import '../../../assets/icons/icon-whatsapp.svg';
