import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../components/images/Sprits/icons';
import '../assets/global-style/style.scss';
import Burger from '../components/other/Burger/';
// import { blockBody, unBlockBody } from '../utils/utils';

const Global = () => {
    const mobileMenu = document.querySelector('.menu--mobile');
    const $menuLinks = mobileMenu.querySelectorAll('.menu__link');
    const $burger = document.querySelector('.burger');
    const burger = new Burger($burger, toggleMenu);
    const $searchForm = document.querySelector('.search-block__form');
    const $searchInput = $searchForm.querySelector('.search-block__input');

    $menuLinks.forEach((link) => {
        link.addEventListener('click', function () {
            burger.toggleBurger();
        });
    });

    function toggleMenu() {
        mobileMenu.classList.toggle('active');
        if (window.getComputedStyle(document.body).overflow === 'hidden') {
            document.body.removeAttribute('style');
        } else {
            document.body.style.overflow = 'hidden';
        }
    }

    $searchForm.addEventListener('submit', function (e) {
        e.preventDefault();

        if ($searchInput.value) {
            location.href = `/search/${$searchInput.value}`;
        }
        // $searchForm.setAttribute('action', `/search/${this.value}`);
    });
};

Global();
